<template>
  <div>
    <a-modal
      v-model="visibleModalManageStudent"
      class="mod"
      :title="status.isEdit ? 'Edit Student' : 'Create Student'"
      :destroyOnClose="true"
      @cancel="toggleModalManageStudent"
      centered
    >
      <a-menu
        v-if="!status.isEdit"
        v-model="currentMenuForm"
        mode="horizontal"
        class="mb-4"
      >
        <a-menu-item key="single">Single</a-menu-item>
        <a-menu-item key="bulk">Bulk</a-menu-item>
      </a-menu>
      <form-student
        v-if="currentMenuForm.includes('single')"
        :dataStudent="newStudent"
        @handle-change="handleChange"
      />
      <template v-if="currentMenuForm.includes('bulk')">
        <a-form-item label="File Template">
          <div
            class="
              px-3
              py-1
              border
              rounded
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <div>
              <a-icon class="mr-2" type="file-excel" />
              List Student Template.xlsx
            </div>
            <a-tooltip>
              <template slot="title"> Download File </template>
              <a href="/List Student Template.xlsx">
                <a-icon style="cursor: pointer" type="download" />
              </a>
            </a-tooltip>
          </div>
        </a-form-item>
        <a-form-item label="Upload Template">
          <a-upload-dragger
            name="fileBulk"
            :multiple="false"
            :before-upload="beforeUploadFileBulk"
            @change="handleChangeUploadBulk"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p class="ant-upload-hint">
              Only support with our template. Please download first <br />
              and put your data to the template
            </p>
          </a-upload-dragger>
        </a-form-item>
      </template>
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageStudent">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageStudent"
          @click="handleOkModalManageStudent"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <a-modal
      v-model="visibleModalManagePasswordStudent"
      class="mod"
      title="Update Password"
      @cancel="toggleModalManagePasswordStudent"
      centered
    >
      <a-row :gutter="16">
        <a-col :span="24">
          <a-form-item label="New Password">
            <a-input-password
              class="w-100"
              size="large"
              v-model="newPasswordStudent.password"
            />
          </a-form-item>
        </a-col>
        <a-col :span="24">
          <a-form-item label="Confirm New Password">
            <a-input-password
              class="w-100"
              size="large"
              v-model="newPasswordStudent.confirmPassword"
            />
          </a-form-item>
        </a-col>
      </a-row>
      <!-- <form-password-student :dataStudent="newStudent" @handle-change="handleChange" /> -->
      <template slot="footer">
        <a-button
          size="large"
          key="back"
          @click="toggleModalManagePasswordStudent"
        >
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManagePasswordStudent"
          @click="handleOkModalManagePasswordStudent"
        >
          Update Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="d-flex w-100">
        <a-input style="width: 300px;" v-model="search" @change="fetchDataStudent" size="large" placeholder="Search by name..."></a-input>
        <!-- <a-select
          size="large"
          placeholder="Select Status"
          :style="{
            'margin-left': !isMobile ? '20px' : '',
            width: isMobile ? '100%' : '120px',
          }"
          @change="handleSelect"
          v-model="selectedStatus"
        >
          <a-select-option key="all" value="all">All</a-select-option>
          <a-select-option key="active" value="active">Active</a-select-option>
          <a-select-option key="inactive" value="inactive">Inactive</a-select-option>
        </a-select> -->
      </div>
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageStudent('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Student</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-collapse accordion>
        <a-collapse-panel key="1" header="What is Academic Status?">
          <div>Academic status is the current status of the student, whether they are considered to be still studying in the school or not. It has 2 values, <b class="text-success">Active</b> and <b class="text-danger">Inactive</b>.</div>
          <br />
          <div><b class="text-success">Active</b> means the student is still studying in the school on this academic year, not leaving or graduating yet, and having class on current academic year (based on the database).</div>
          <br />
          <div><b class="text-danger">Inactive</b> means the student is already leaving the school, not having class on current academic year, or not being accepted by admin at the PPDB application.</div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <div class="mt-4">
      <a-table
        :columns="studentColumns"
        :data-source="studentDataTable"
        :pagination="studentPagination"
        :loading="studentLoadingTable"
        bordered
        class="table-scroll"
      >
        <div slot="status_spp" class="text-capitalize" slot-scope="value">
          <a-tag :color="value == 'unpaid' ? 'red' : 'green'">{{value ?? 'Paid' }}</a-tag>
        </div>
        <span slot="academicStatus" slot-scope="value">
          <a-tag :color="value == 'active' ? 'green' : 'red'">{{value ?? 'Inactive' }}</a-tag>
        </span>
        <div
          class="d-flex flex-column"
          slot="action"
          slot-scope="value, record"
        >
          <a-button
            @click.prevent="toggleModalManageStudent('edit', record)"
            class="mb-3 text-warning border border-warning"
            size="default"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="
              toggleModalManagePasswordStudent('edit-password', record)
            "
            class="mb-3 text-primary border border-primary"
            size="default"
          >
            <a-icon type="key" />Update Password
          </a-button>
          <a-button
            @click.prevent="handleDeleteStudent(record)"
            class="text-danger border border-danger"
            size="default"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx'
const formStudent = () => import('@/components/app/Admin/Master/Form/Student')

const studentColumns = [
  {
    title: 'NIS',
    dataIndex: 'nis',
    key: 'nis',
    scopedSlots: { customRender: 'nis' },
    width: 130,
  },
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
    width: 250,
  },
  {
    title: 'E-mail',
    dataIndex: 'email',
    key: 'email',
    scopedSlots: { customRender: 'email' },
    width: 170,
  },
  // {
  //   title: "Parent's E-mail",
  //   dataIndex: 'email_perwakilan_orangtua',
  //   key: 'email_perwakilan_orangtua',
  //   scopedSlots: { customRender: 'email' },
  //   width: 170,
  // },
  // {
  //   title: 'SPP Status',
  //   dataIndex: 'status_spp',
  //   key: 'status_spp',
  //   scopedSlots: { customRender: 'status_spp' },
  //   width: 120,
  //   align: 'center',
  // },
  // {
  //   title: 'Gender',
  //   dataIndex: 'jenis_kelamin',
  //   key: 'jenis_kelamin',
  //   scopedSlots: { customRender: 'jenis_kelamin' },
  //   width: 125,
  // },
  {
    title: 'Academic Status',
    dataIndex: 'academicStatus',
    key: 'academicStatus',
    scopedSlots: { customRender: 'academicStatus' },
    align: 'center',
    width: 120,
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
    width: 100,
  },
]
export default {
  name: 'Student',
  components: {
    formStudent,
  },
  data() {
    return {
      studentColumns,
      visibleModalManageStudent: false,
      loadingActionModalManageStudent: false,
      visibleModalManagePasswordStudent: false,
      loadingActionModalManagePasswordStudent: false,
      loadingDeleteStudent: false,
      studentLoadingTable: false,
      studentDataTable: [],
      studentPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newStudent: {
        nis: null,
        nisn: null,
        nama: null,
        jenis_kelamin: null,
        status_spp: 'paid',
        agama: null,
        alamat: null,
        email: null,
        email_perwakilan_orangtua: null,
        id_parent_account: null,
      },
      newStudentsBulk: [],
      newPasswordStudent: {
        password: null,
        confirmPassword: null,
      },
      idEdit: null,
      currentMenuForm: ['single'],
      search: null,
      order: 'ASC',
      sortBy: 'id',
      selectedStatus: 'all',
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      // console.log('parent', payload)
      this.newStudent[column] = value
    },
    handleChangeUploadBulk(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        // console.log('Change')
      }
      if (status === 'done') {
        // this.$message.success(`${info.file.name} file uploaded successfully.`)
        console.log('Upload Success')
      } else if (status === 'error') {
        console.log('Upload Failed')
        // this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    beforeUploadFileBulk(file) {
      const acceptFile = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']
      if (!acceptFile.includes(file.type)) {
        return this.$notification.error({
          message: 'Warning',
          description: 'Only XLSX file is allowed',
        })
      // } else if (file.name !== 'List Student Template.xlsx') {
      //   return this.$notification.error({
      //     message: 'Warning',
      //     description: 'Only with our template file is allowed',
      //   })
      } else {
        const reader = new FileReader()
        reader.onload = () => {
          const fileData = reader.result
          const wb = XLSX.read(fileData, { type: 'binary' })
          if (wb.Sheets.StudentList) {
            const sheets = XLSX.utils.sheet_to_json(wb.Sheets.StudentList)
            console.log(wb.Sheets.StudentList)
            console.log(sheets)
            this.newStudentsBulk = sheets.map(s => ({ ...s, status_spp: 'paid' }))
          } else {
            this.$notification.error({
              message: 'Warning',
              description: 'This document is not our template',
            })
          }
        }
        reader.readAsBinaryString(file)
        return false
      }
    },
    toggleModalManageStudent(status, data) {
      this.visibleModalManageStudent = !this.visibleModalManageStudent
      this.currentMenuForm = ['single']
      if (!this.visibleModalManageStudent) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newStudent = {
            nis: null,
            nisn: null,
            nama: null,
            jenis_kelamin: null,
            status_spp: 'paid',
            agama: null,
            alamat: null,
            email: null,
            email_perwakilan_orangtua: null,
            id_parent_account: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newStudent = {
            nis: data.nis,
            nisn: data.nisn,
            nama: data.nama,
            jenis_kelamin: data.jenis_kelamin,
            status_spp: data.status_spp || 'paid',
            agama: data.agama,
            alamat: data.alamat,
            email: data.email,
            email_perwakilan_orangtua: data.email_perwakilan_orangtua,
            id_parent_account: data.id_parent_account,
          }
        }
      }
    },
    handleOkModalManageStudent() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this student?' : 'Are you sure want to create new student'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageStudent = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataStudent: this.newStudent,
            } : this.currentMenuForm.includes('bulk') ? {
              dataStudent: this.newStudentsBulk,
            } : {
              dataStudent: [this.newStudent],
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_STUDENT`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageStudent = false
                this.toggleModalManageStudent()
                this.fetchDataStudent()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Student has been updated' : 'Student has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Student has not been updated' : 'Student has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    toggleModalManagePasswordStudent(status, data) {
      this.visibleModalManagePasswordStudent = !this.visibleModalManagePasswordStudent
      if (!this.visibleModalManagePasswordStudent) {
        setTimeout(() => {
          this.idEdit = null
          this.newPasswordStudent = {
            password: null,
            confirmPassword: null,
          }
        }, 500)
        return ''
      }

      if (status === 'edit-password') {
        this.idEdit = data.key
      }
    },
    handleOkModalManagePasswordStudent() {
      if (this.isValidatePasswordForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        if (this.newPasswordStudent.password !== this.newPasswordStudent.confirmPassword) {
          this.$notification.error({
            message: 'Warning',
            description: 'New Password does not match',
          })
        } else {
          this.$confirm({
            title: 'Warning',
            content: (
              <div>Are you sure want update this student password?</div>
            ),
            onOk: () => {
              this.loadingActionModalManagePasswordStudent = true
              const payload = {
                id: this.idEdit,
                password: this.newPasswordStudent.password,
              }
              this.$store.dispatch('master/UPDATE_PASSWORD_STUDENT', payload)
                .then(isSuccess => {
                  this.loadingActionModalManagePasswordStudent = false
                  this.toggleModalManagePasswordStudent()
                  this.fetchDataStudent()
                  if (isSuccess) {
                    const description = 'Student Password has been updated'
                    this.$notification.success({
                      message: 'Success',
                      description,
                    })
                  } else {
                    const description = 'Student Password has not been updated'
                    this.$notification.error({
                      message: 'Failed',
                      description,
                    })
                  }
                })
            },
            onCancel: () => {
            },
            centered: true,
            icon: 'warning',
            okType: 'primary',
            okText: 'Update Now',
            cancelText: 'Cancel',
          })
        }
      }
    },
    handleDeleteStudent(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this student?</div>
        ),
        onOk: () => {
          this.studentLoadingTable = true
          this.loadingDeleteStudent = true
          this.$store.dispatch('master/DELETE_STUDENT', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataStudent()
              this.loadingDeleteStudent = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Student has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Student has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteStudent = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataStudent() {
      this.studentLoadingTable = true
      this.$store.dispatch('master/FETCH_STUDENT', { page: this.studentPagination.current, order: this.order, search: this.search, sortBy: this.sortBy, academicStatus: this.selectedStatus === 'all' ? null : this.selectedStatus })
        .then(res => {
          this.studentLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.studentDataTable = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
    handleSelect() {
      this.fetchDataStudent()
    },
  },
  mounted() {
    this.fetchDataStudent()
  },
  computed: {
    isValidateForm() {
      if (this.currentMenuForm.includes('single')) {
        if (this.newStudent.nis && this.newStudent.nama && this.newStudent.jenis_kelamin && this.newStudent.agama && this.newStudent.status_spp) {
          return false
        }
      } else if (this.currentMenuForm.includes('bulk')) {
        if (this.newStudentsBulk.length) {
          return false
        }
      }
      return true
    },
    isValidatePasswordForm() {
      if (this.newPasswordStudent.password && this.newPasswordStudent.confirmPassword) {
        return false
      }
      return true
    },
    isMobile() {
      return this.$store.getters.isMobile
    },
    isTablet() {
      return this.$store.getters.isTablet
    },
    isDesktop() {
      return !this.isMobile && !this.isTablet
    },
  },
}
</script>

<style lang="scss">
.table-scroll {
  .ant-spin-nested-loading .ant-spin-container .ant-table {
    overflow-x: auto;
  }
}
</style>
